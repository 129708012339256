/* You can add global styles to this file, and also import other style files */
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$theme-primary: mat.define-palette(mat.$indigo-palette);
$theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

$gold: #C7922A;
$yellow-white: #FFE1A5;
$gray: #0000004D;
$gray-light: #00000029;

body {
  font-family: 'Poppins', sans-serif;
  background-color: #C7922AFF;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


h2{
  font-family: 'Poppins', sans-serif;
  font: normal normal bold 38px/40px Poppins;
  color: #000000;
  text-shadow: 0 0 25px #0000004D;
  text-transform: uppercase;
}

h4{
  font-family: 'Poppins', sans-serif;
  font: normal normal bold 16px/19px Poppins;
  letter-spacing: 0;
  color: #000000;
  text-transform: uppercase;
}

.hover_color {
  position: relative;
  margin: 0;
  display: inline-block;
}

.hover_color::before {
  position: absolute;
  content: '';
  left: 0;
  top: 100%;
  right: 0;
  bottom: 0;
  background: rgba(199,146,42,.8);
  transition: all 350ms;
}

.hover_color:hover::before {
  top: 0;
}

.hover_color img {
  width: 100%;
  height: 100%;
  margin-bottom: -3px;
}

.hover_color .hover_color-inner {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hover_title {
  transform: translateY(-4px);
  opacity: 0;
  transition: all 350ms;
  position: relative;
  width: 60px;
  height: 60px;

  .cross-top, .cross-mid{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cross-mid{
    width: 60px;
    height: 2px;
    background-color: #ffff;
  }

  .cross-top{
    width: 2px;
    height: 60px;
    background-color: #ffff;
  }
}

.hover_color a {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.hover_color:hover .hover_title {
  transform: translateY(0);
  opacity: 1;
}

main{
  margin: 1rem;
}

.section-musee-top{
  width: 100%;
  position: relative;

  .img-bandeau{
    max-width: 100%;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .logo{
    position: absolute;
    left: 7%;
    top: 40px;
    max-width: 86%;
  }

  .en-savoir-plus-container{
    position: absolute;
    right: 50px;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;

    @media (max-width: 699px) {
      right: 0px;
      top: 210px;
    }
    h2 {
      @media (max-width: 850px) {
        display: none;
      }
    }
  }

  .en-savoir-plus{
    font: normal normal 600 16px/19px Poppins;
    color: #000000;
    text-decoration: none;
    background-color: white;
    padding: .5rem 1rem;
    box-shadow: 0 0 6px #000000;
    border: 1px solid #ADADAD;
    border-radius: 2px;
    margin-right: 1rem;
  }

  .search-bar-container{
    position: absolute;
    top: 220px;
    right: 51%;
    transform: translateX(50%);

    @media (max-width: 770px) {
      position: relative;
      width: 100%;
      top: -4px;
      margin-bottom: -36px;
      right: 1px;
      transform: none !important;
    }
  }
}

.collection-container{
  width: 100%;
  background-color: #ffff;
  margin-top: -1.5rem;
  padding-top: 4rem;

  .text-container{
    width: 100%;
    display: flex;
    justify-content: center;

    .text-collection{
      font: normal normal normal 18px/22px Poppins;
      color: #000000;
      text-align: center;
      width: 70%;
    }
  }

  .credit-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    .text-credit {
      font: normal normal normal 18px/22px Poppins;
      margin: 0 2rem 0 2rem;
    }
  }
}

.card-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1rem 5rem 1rem;
}

.card{
  position: relative;
  height: 400px;
  width: 500px;
  margin: 3rem 1rem;

  @media (max-width:500px) {
    height: auto;
  }

  h2{
    margin-top: 2rem;
    text-align: center;
    position: relative;
    right: 5%;
    font-family: 'Poppins', sans-serif;
    font: normal normal bold 16px/19px Poppins;
    letter-spacing: 0;
    color: #000000;
    text-transform: uppercase;

    @media (max-width:500px) {
      right: unset;
    }
  }

  .img-card{
    width: 100%;
    height: 365px;
    max-width: 100%;

    @media (max-width:500px) {
      height: 100%;
    }
  }

  .card-bar{
    width: 3px;
    height: 60px;
    background-color: #C7922A;
    position: absolute;
    right: 55%;
    bottom: 5px;
    transform: translateX(50%);

    @media (max-width:500px) {
      display: none;
    }
  }
}

.card-small{
  margin-left: 1rem;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-form-field-underline {
   width: 0 !important;
}

.ngx-pagination {
  padding-left: 0px !important;
}

.ngx-pagination .current,
.btn-info {
  background: #30575C !important;
  border: transparent !important;
  border-radius: 50% !important;
}

.ngx-pagination .current:hover {
  background: #30575C !important;
  border-radius: 50% !important;
  border: transparent !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

#collection-en-ligne {
  @media (max-width: 880px) {
    display: none !important;
  }
}

.collection-container {
  @media (max-width: 1050px) {
    padding-top: 5rem;
  }

  @media (max-width: 700px) {
    padding-top: 3rem;
  }
}

.mat-option {
  white-space: pre-wrap !important;
  line-height: 1rem !important;
}

.mat-select-panel {
  overflow-x: hidden !important;
}
